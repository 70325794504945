<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "hrd-orientation-evaluation-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'hrd-orientation-evaluation-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                          'hrd-orientation-evaluation-update'
                        "
                        :options="batch"
                        :clearable="false"
                        :selectable="(options) => isSelectableBatch(options)"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="
                          currentRouteName ==
                          'hrd-orientation-evaluation-update'
                        "
                        :selectable="(option) => isSelectable(option)"
                        :options="employeeLists"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="orientation.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="orientation.join_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
              </div>

              <div class="row g-3 mt-1">
                <div class="col-sm-4">Orientation Assessment</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="orientationAssessmentScore"
                    disabled
                  />
                </div>

                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="hrdOrientationScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3 mt-1">
                <div class="col-sm-4">Behavior Assessment</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="behaviorAssessmentScore"
                    disabled
                  />
                </div>

                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="hrdBehaviorScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3 mt-1">
                <div class="col-sm-4">Achievement</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="achievement"
                    disabled
                  />
                </div>

                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="hrdEvaluationScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3 mt-1">
                <div class="col-sm-4">HRD Recommand</div>
                <div class="col-sm-4">
                  <v-select
                    v-model="selectedHrdAssessment"
                    label="name"
                    :options="hrdAssessment"
                    disabled
                    :clearable="false"
                  >
                  </v-select>
                </div>
              </div>

              <div class="row g-3 mt-1">
                <div class="col-sm-4">Remark</div>
                <div class="col-sm-4">
                  <input
                    v-if="
                      hasPermissions([
                        'hrd-orientation-evaluation-create',
                        'hrd-orientation-evaluation-edit',
                      ])
                    "
                    type="text"
                    class="form-control"
                    v-model="remark"
                    placeholder="Remark"
                  />
                  <input
                    v-else-if="
                      hasPermissions(['hrd-orientation-evaluation-list'])
                    "
                    type="text"
                    class="form-control"
                    v-model="remark"
                    placeholder="Remark"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row"
                v-if="
                  hasPermissions([
                    'hrd-orientation-evaluation-create',
                    'hrd-orientation-evaluation-edit',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'hrd-orientation-evaluation' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <!-- <button
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="Please fill orientatoi"
                    >
                    </button> -->
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      :style="{ cursor: notAllow ? 'not-allowed' : 'pointer' }"
                      v-bind="
                        notAllow
                          ? {
                              'data-bs-toggle': 'tooltip',
                              title: 'Fill all assessments!',
                            }
                          : {}
                      "
                      @click="
                        currentRouteName === 'hrd-orientation-evaluation-update'
                          ? updateOrientationEvaluation()
                          : createOrientationEvaluation()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: [],
      batch: [],
      employeeLists: [],
      orientationAssessmentScore: 0,
      behaviorAssessmentScore: 0,
      achievement: 0,
      hrdOrientationScore: 0,
      hrdBehaviorScore: 0,
      behaviorAssessmentId: null,
      hrdAssessment: [
        { id: 1, name: "Poor", highest_weight: 49, lowest_weight: 0 },
        {
          id: 2,
          name: "Need Improvement",
          highest_weight: 69,
          lowest_weight: 50,
        },
        {
          id: 3,
          name: "Meet Expectation",
          highest_weight: 79,
          lowest_weight: 70,
        },
        {
          id: 4,
          name: "Exceed Expectation",
          highest_weight: 89,
          lowest_weight: 80,
        },
        { id: 5, name: "Excellent", highest_weight: 100, lowest_weight: 90 },
      ],
      existedEmployee: [],
      notAllow: false,
      selectedHrdAssessment: {
        id: 1,
        name: "Poor",
        highest_weight: 49,
        lowest_weight: 0,
      },
      hrdEvaluationScore: 0,
      remark: null,
      orientation: {
        type: "ORI",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
        employee_id: "",
        employee_name: "",
      },
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      orientation: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        available: { required },
      },
    };
  },
  methods: {
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectable(option) {
      return (
        this.existedEmployee.find(
          (item) => item.behavior_assessments.employee_id === option.employee_id
        ) === undefined
      );
      //   existedEmployees.find((item) => item.employee_id != option.employee_id
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.clearData();
        this.orientation.program_id = this.selectedProgram.id;
        this.orientation.program_name = this.selectedProgram.name;
        this.orientation.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.orientation.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.orientation.program_batch_id = this.selectedBatch.id;
        this.orientation.program_batch_no = this.selectedBatch.batch_no;
        this.orientation.available = this.selectedBatch.available;
        this.getEmployeeByBatch(this.orientation.program_batch_id);
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.orientation.employee_id = this.selectedEmployee.employee_id;
        this.orientation.employee_name = this.selectedEmployee.employee_name;
        this.orientation.join_date = this.selectedEmployee.join_date;

        this.getScoreByEmployee();
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.notAllow = false;
      this.orientationAssessmentScore = 0;
      this.behaviorAssessmentScore = 0;
      this.selectedHrdAssessment = {
        id: 1,
        name: "Poor",
        highest_weight: 49,
        lowest_weight: 0,
      };
      this.hrdEvaluationScore = 0;
      this.hrdBehaviorScore = 0;
      this.hrdOrientationScore = 0;
      this.achievement = 0;
      this.remark = "";
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batches/list/?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batch/employees/list/?batch_id=${batch_id}`
        )
        .then((response) => {
          this.employeeLists = response.data.data[0].employee_ids.map(
            (item) => {
              return {
                employee_name: item.name,
                employee_id: item.employee_ID,
                join_date: item.join_date,
              };
            }
          );

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getScoreByEmployee() {
      this.loading = true;
      this.$Progress.start();
      this.notAllow = false;
      await axios
        .get(
          `${this.baseUrl}admin/v2/hrd-orientation-evaluation/getScore/${this.orientation.program_id}/${this.orientation.program_batch_id}/${this.selectedEmployee.employee_id}`
        )
        .then((response) => {
          // console.log(response.data.data)
          if (
            response.data.data.behaviorAssessmentScore == null ||
            response.data.data.orientationAssessmentScore == null
          ) {
            this.notAllow = true;
          }
          this.behaviorAssessmentId = response.data.data.behaviorAssessmentScore
            ? response.data.data.behaviorAssessmentScore.behavior_assessment_id
            : null;
          this.orientationAssessmentScore = response.data.data
            .orientationAssessmentScore
            ? response.data.data.orientationAssessmentScore
            : 0;
          this.behaviorAssessmentScore = response.data.data
            .behaviorAssessmentScore
            ? response.data.data.behaviorAssessmentScore.percentage
            : 0;
          this.hrdOrientationScore = response.data.data.orientationHrdScore
            ? response.data.data.orientationHrdScore
            : 0;
          this.hrdBehaviorScore = response.data.data.behaviorAssessmentScore
            ? response.data.data.behaviorAssessmentScore.hrd_average_score
            : 0;
          this.achievement =
            this.orientationAssessmentScore + this.behaviorAssessmentScore;

          this.hrdEvaluationScore = Math.round(
            ((this.hrdOrientationScore + this.hrdBehaviorScore) / 200) * 100
          );
          this.hrdEvaluationScore =
            this.hrdEvaluationScore > 100 ? 100 : this.hrdEvaluationScore;

          this.hrdAssessment.forEach((item) => {
            if (
              this.hrdEvaluationScore <= item.highest_weight &&
              this.hrdEvaluationScore >= item.lowest_weight
            ) {
              return (this.selectedHrdAssessment = item);
            }
          });
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$Progress.fail();
        });
    },
    async createOrientationEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      if (this.notAllow) return;
      this.isLoading = true;

      const toSendData = {
        behavior_assessment_id: this.behaviorAssessmentId,
        orientation_assessment_score: this.orientationAssessmentScore,
        behavior_assessment_score: this.behaviorAssessmentScore,
        achievement: this.achievement,
        hrd_orientation_score: this.hrdOrientationScore,
        hrd_behavior_score: this.hrdBehaviorScore,
        hrd_evaluation_score: this.hrdEvaluationScore,
        assessment_result: this.selectedHrdAssessment?.name,
        remark: this.remark,
      };
      // console.log(JSON.stringify(toSendData));
      await axios
        .post(
          `${this.baseUrl}admin/v2/hrd-orientation-evaluation`,
          toSendData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.toast.success("Successfully Orientation Evaluation Created.");
          this.$router.push({ name: "hrd-orientation-evaluation" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },
    async getOrientationEvaluationById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/hrd-orientation-evaluation/${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data;
          this.behaviorAssessmentId = data.behavior_assessment_id;
          this.orientationAssessmentScore = data.orientation_assessment_score;
          this.behaviorAssessmentScore = data.behavior_assessment_score;
          this.achievement =
            this.orientationAssessmentScore + this.behaviorAssessmentScore;
          this.selectedProgram = data.behavior_assessments.program_name;
          this.selectedBatch = data.behavior_assessments.program_batch_no;
          this.selectedEmployee = data.behavior_assessments.employee_name;
          this.hrdAssessment.forEach((item) => {
            if (item.name == data.assessment_result)
              return (this.selectedHrdAssessment = item);
          });
          this.hrdBehaviorScore = data.hrd_behavior_score;
          this.hrdEvaluationScore = data.hrd_evaluation_score;
          this.hrdOrientationScore = data.hrd_orientation_score;
          this.remark = data.remark;
          this.orientation = data.behavior_assessments;

          console.log(this.orientationAssessmentScore, "orientation");
          if (
            this.orientationAssessmentScore == 0 ||
            this.behaviorAssessmentScore == 0
          ) {
            this.notAllow = true;
          } else {
            this.notAllow = false;
          }
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.loading = false;
        });
    },
    async updateOrientationEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      if (this.notAllow) return;

      this.isLoading = true;

      const toSendData = {
        behavior_assessment_id: this.behaviorAssessmentId,
        orientation_assessment_score: this.orientationAssessmentScore,
        behavior_assessment_score: this.behaviorAssessmentScore,
        achievement: this.achievement,
        hrd_orientation_score: this.hrdOrientationScore,
        hrd_behavior_score: this.hrdBehaviorScore,
        hrd_evaluation_score: this.hrdEvaluationScore,
        assessment_result: this.selectedHrdAssessment?.name,
        remark: this.remark,
      };

      await axios
        .put(
          `${this.baseUrl}admin/v2/hrd-orientation-evaluation/${this.$route.params.id}`,
          toSendData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.toast.success("Successfully Orientation Evaluation Updated.");
          this.$router.push({ name: "hrd-orientation-evaluation" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },
    async getExistedEmployee() {
      await axios
        .get(
          `${this.baseUrl}admin/v2/hrd-orientation-evaluation-getExistedEmployee`
        )
        .then((response) => {
          this.existedEmployee = response.data.data;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  created() {
    this.getPrograms();
    if (this.currentRouteName == "hrd-orientation-evaluation-update") {
      this.getOrientationEvaluationById();
    }
    this.getExistedEmployee();
    if (
      this.orientationAssessmentScore == 0 ||
      this.behaviorAssessmentScore == 0
    ) {
      this.notAllow = true;
    } else {
      this.notAllow = false;
    }
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.cursorNotAllow {
  cursor: not-allowed;
}
</style>
